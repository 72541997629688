import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

interface Props {
  componentType: string;
  componentName: string;
  changeVersion?: (version: string) => void;
}

export default function VersionsTab({ componentType, componentName }: Props) {
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    const path = `/api/v1/${componentType}/${componentName}/versions`;

    axios.get(path).then((resp) => {
      setVersions(resp.data.data);
    });
  }, [componentType, componentName]);

  return (
    <ul>
      {versions.map((v) => (
        <li key={v}>
          <Link to={`/${componentType}/${componentName}/${v}`}>{v}</Link>
        </li>
      ))}
    </ul>
  );
}
