import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'timeago.js';
import cn from 'classnames';

import styles from './component.module.css';
import Markdown from './markdown';
import VersionsTab from './versions';
import List from '../../components/list';

const defaultComponent = {
  name: '',
  readme: '',
  requirements: [],
};

interface Component {
  name: string;
  version: string;
  href: string;
  altLogo: string;
  icon: string;
  readme: string;
  overview: string;
  maintainers: Array<{
    name: string;
  }>;
  dependencies: Array<{
    name: string;
    version: string;
  }>;
  requirements: Array<{
    name: string;
    version: string;
  }>;
  updatedAt: string;
  tags: string[];
  installationInstruction: string[];
  upgradeInstruction: string[];
}

interface Props {
  match: {
    params: {
      componentType: string;
      componentName: string;
      scope: string;
      componentVersion?: string;
    };
  };
}

function CodeString({ code }: { code: string }) {
  const [copied, setCopied] = useState('');

  return (
    <button
      className={styles['code-string']}
      onClick={() => {
        navigator.clipboard
          .writeText(code)
          .then(() => {
            setCopied('Copied!');
            setTimeout(() => setCopied(''), 3000);
          })
          .catch((e) => {
            setCopied('Failed to copy :(');
            setTimeout(() => setCopied(''), 3000);
            console.log(e);
          });
      }}
    >
      <>{copied ? <code>{copied}</code> : <code>{code}</code>}</>
    </button>
  );
}

const tabs = [
  {
    name: 'overview',
    title: 'OVERVIEW',
  },
  {
    name: 'readme',
    title: 'README',
  },
  {
    name: 'dependencies',
    title: 'DEPENDENCIES',
  },
  {
    name: 'versions',
    title: 'VERSIONS',
  },
];

function switcher(
  activeTab: string,
  component: Component,
  componentType: string
): React.ReactNode {
  switch (activeTab) {
    case 'overview':
      return (
        <Markdown
          markdown={component.overview}
          label="overview"
        />
      );
    case 'readme':
      return (
        <Markdown
          markdown={component.readme}
          label="readme"
        />
      );
    case 'dependencies':
      return (
        <List
          items={component.dependencies.map((d) => `${d.name} — ${d.version}`)}
        />
      );
    case 'versions':
      return (
        <VersionsTab
          componentType={componentType}
          componentName={component.name}
        />
      );
    default:
  }
}

export default function Component({
  match: {
    params: { componentType, componentName, scope, componentVersion },
  },
}: Props) {
  const [component, setComponent] = useState<Component>(
    (defaultComponent as unknown) as Component
  );
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    let path = `/api/v1/${componentType}/${componentName}`;
    if (scope !== '') {
      path = `/api/v1/${componentType}/@${scope}/${componentName}`;
    }
    if (componentVersion) {
      path += `/${componentVersion}`;
    }

    axios.get(path).then((resp) => {
      setComponent(resp.data.data);

      if (resp.data.data.overview) {
        setActiveTab('overview');
        return;
      }

      setActiveTab('readme');
    });
  }, [componentType, componentName, componentVersion, scope]);

  const TagContent = switcher(activeTab, component, componentType);

  return (
    <div className={styles['component']}>
      <div>
        <h2 className={styles['component-name']}>{component.name}</h2>
        <p className={styles['component-version-time']}>
          {component.version} &#9775; Published {format(component.updatedAt)}
        </p>
      </div>
      <div>
        <ul
          role="tablist"
          aria-label="Component info"
          className={styles['tabs']}
        >
          {tabs
            .filter((t) => t.name !== 'overview' || component.overview)
            .map((t) => (
              <li
                key={t.title}
                role="tab"
                aria-selected={activeTab === t.name}
                className={cn(styles[t.name], {
                  [styles['tab-active']]: activeTab === t.name,
                })}
                id={`tab-${t.name}`}
              >
                <button onClick={() => setActiveTab(t.name)}>{t.title}</button>
              </li>
            ))}
        </ul>
      </div>
      <main className={styles['main']}>
        <div
          className={styles['item']}
          role="tabpanel"
          aria-labelledby={`tab-${activeTab}`}
        >
          {TagContent}
        </div>
        <div className={styles['sidebar']}>
          <div aria-label="installation">
            <p>
              <strong>Install</strong>
            </p>
            {component.installationInstruction ? (
              component.installationInstruction.map((v, i) => (
                <CodeString key={i} code={v} />
              ))
            ) : (
              <CodeString
                code={`npm i ${component.name}${
                  componentVersion ? `@${componentVersion}` : ''
                }`}
              />
            )}
            <p>
              <strong>Or upgrade</strong>
            </p>
            {component.upgradeInstruction ? (
              component.upgradeInstruction.map((v, i) => (
                <CodeString key={i} code={v} />
              ))
            ) : (
              <CodeString code={`npm i ${component.name}@latest`} />
            )}
          </div>
          <div>
            <p>
              <strong>Requirements</strong>
            </p>
            <List
              items={component.requirements.map(
                (r) => `${r.name} — ${r.version}`
              )}
            />
          </div>
        </div>
      </main>
    </div>
  );
}
