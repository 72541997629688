import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';

import Item from '../../components/itemv2';

import blocks from './blocks.svg';
import styles from './my-components.module.css';

const plural: Record<string, string> = {
  connector: 'connectors',
  policy: 'policies',
  flow: 'flows',
  loader: 'loaders',
};

interface Component {
  name: string;
  version: string;
  componentType: string;
  title: string;
  href: string;
  altLogo: string;
  icon: string;
  description: string;
  maintainers: Array<{
    name: string;
  }>;
  updatedAt: string;
  tags: string[];
}

export default function MyComponent() {
  const [components, setComponents] = useState<Component[]>([]);

  useEffect(() => {
    axios
      .get('/api/v1/profile/my-components', {
        withCredentials: true,
      })
      .then((resp) => {
        setComponents(resp.data.data);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 401) {
          console.error(e.response.data);
          window.location.replace('/');
        }
      });
  }, []);

  return (
    <main className={styles.main}>
      <h2>My Components</h2>
      <ul className={styles['components-list']}>
        {components.map((c: Component) => {
          return (
            <li key={`${plural[c.componentType]}/${c.name}/${c.title}`}>
              <Item
                href={`/${plural[c.componentType]}/${c.name}`}
                altLogo={c.altLogo}
                logo={c.icon || blocks}
                title={c.title}
                description={c.description}
                owner={`${c.maintainers[0].name}`}
                updated={c.updatedAt}
                tags={c.tags}
              />
            </li>
          );
        })}
      </ul>
    </main>
  );
}
