import React from 'react';
import styles from './tag.module.css';

export default function Tag({ tag }: { tag: string }) {
  return (
    <span className={styles['tag']}>
      {tag}
    </span>
  );
}
