import React from 'react';
import cn from 'classnames';

import styles from './button.module.css';

interface Props {
  children: React.ReactNode;
  className?: string;
  borderless?: boolean;
  onClick?: () => void;
}

export default function Button({
  children,
  className,
  borderless,
  onClick,
}: Props) {
  return (
    <button
      className={cn(
        styles.button,
        {
          [styles.borderless]: borderless,
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
