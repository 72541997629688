import React from 'react';

import logo from './logo.svg';
import blocks from './blocks.svg';
import guidelines from './guidelines.svg';
import interfaces from './interfaces.svg';
import styles from './home.module.css';
import Search from '../../components/search';

export default function Home() {
  return (
    <div className={styles.content}>
      <div className={styles.banner}>
        <div className={styles['banner-content']}>
          <div className={styles['banner-info']}>
            <div className={styles.logo}>
              <img alt="platformeco logo — big P" src={logo} />
            </div>
            <div className={styles.title}>
              <h1>Platformeco Hub</h1>
              <h4>
                Create amazing things. Library of plugins for Platformeco.
              </h4>
            </div>
          </div>
          <div className={styles.search}>
            <Search />
          </div>
        </div>
      </div>
      <main className={styles.main}>
        <div className={styles['first-row']}>
          <div className={styles.anchor}>
            <h2 className={styles.about}>
              Most Advanced Low&#8209;Code microservices Platform
            </h2>
            <span>
              Distributed Cloud Native technology platform, which allows product
              &amp; project teams easily build &amp; operate microservices using
              Low&#8209;Code interface in self-service mode.
            </span>
          </div>
          <section className={styles.features}>
            <div className={styles.feature}>
              <img alt="blocks" src={blocks} />
              <h4>Building Blocks</h4>
            </div>
            <div className={styles.feature}>
              <img alt="computer screen" src={interfaces} />
              <h4>Complex Connectors</h4>
            </div>
            <div className={styles.feature}>
              <img alt="connected lists" src={guidelines} />
              <h4>Battle-ready APIs & Flows</h4>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
