import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'timeago.js';

import styles from './item.module.css';
import Tag from '../tag';

interface Props {
  href: string;
  logo: string;
  altLogo?: string;
  title: string;
  description: string;
  owner: string;
  updated: string;
  tags: string[];
}

export default function Item({
  href,
  logo,
  altLogo,
  title,
  description,
  owner,
  updated,
  tags,
}: Props) {
  const linkRef = useRef(null);

  return (
    <div
      className={styles['item']}
      onClick={() => {
        if (linkRef) {
          ((linkRef.current as unknown) as HTMLLinkElement).click();
        }
      }}
    >
      <div className={styles['header']}>
        <img
          className={styles['logo']}
          alt={altLogo || 'empty logo'}
          src={logo}
        />
        <div className={styles['info']}>
          <span>Owner: {owner}</span>
          <span>Updated: {format(updated)}</span>
        </div>
      </div>
      <div className={styles['title']}>
        <Link to={href} ref={linkRef}>
          {title}
        </Link>
      </div>
      <div className={styles['description']}>{description}</div>
      <div className={styles['tags']}>
        {tags.map((tag) => (
          <Tag key={tag} tag={tag} />
        ))}
      </div>
    </div>
  );
}
