import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';

import styles from './code-block.module.css';

export default function CodeBlock({
  value,
  language,
}: {
  value: string;
  language: string;
}) {
  return (
    <SyntaxHighlighter className={styles.code} language={language}>
      {value}
    </SyntaxHighlighter>
  );
}
