import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import styles from './app.module.css';

import Home from './pages/home';
import TopNav from './components/top-nav';
import Components from './pages/components';
import HowTo from './pages/how-to';
import Component from './pages/component';
import MyComponents from './pages/my-components';
import Examples from './pages/examples';
import Example from './pages/example';

const pages = [
  {
    href: '/',
    title: 'Home',
    component: Home,
  },
  {
    href: '/:componentType(connectors|policies|flows|loaders)',
    title: 'Connectors | Policies | Shared Flow | Loaders',
    component: Components,
  },
  {
    href: '/how-to',
    title: 'how to build',
    component: HowTo,
  },
  {
    href:
      '/:componentType(connectors|policies|flows|loaders)/@:scope/:componentName',
    title: 'component',
    component: Component,
    navigation: false,
  },
  {
    href: '/:componentType(connectors|policies|flows|loaders)/:componentName',
    title: 'component',
    component: Component,
    navigation: false,
  },
  {
    href:
      '/:componentType(connectors|policies|flows|loaders)/@:scope/:componentName/:componentVersion',
    title: 'component',
    component: Component,
    navigation: false,
  },
  {
    href:
      '/:componentType(connectors|policies|flows|loaders)/:componentName/:componentVersion',
    title: 'component',
    component: Component,
    navigation: false,
  },
  {
    href: '/profile/my-components',
    title: 'My Components',
    component: MyComponents,
    navigation: false,
  },
  {
    href: '/examples',
    title: 'Examples',
    component: Examples,
    navigation: false,
  },
  {
    href:
      '/examples/@:scope/:exampleName',
    title: 'component',
    component: Example,
    navigation: false,
  },
  {
    href: '/examples/:exampleName',
    title: 'component',
    component: Example,
    navigation: false,
  },
  {
    href:
      '/examples/@:scope/:exampleName/:exampleVersion',
    title: 'component',
    component: Example,
    navigation: false,
  },
  {
    href:
      '/examples/:exampleName/:exampleVersion',
    title: 'component',
    component: Example,
    navigation: false,
  },
];

const navigation = [
  {
    href: '/',
    title: 'Home',
  },
  {
    href: '/connectors',
    title: 'Connectors',
  },
  {
    href: '/policies',
    title: 'Policies',
  },
  {
    href: '/loaders',
    title: 'Loaders',
  },
  {
    href: '/examples',
    title: 'Examples',
  },
  {
    href: '/how-to',
    title: 'how to build',
  },
];

function App() {
  return (
    <BrowserRouter>
      <TopNav pages={navigation} />
      <Switch>
        {pages.map((page) => (
          <Route
            exact
            key={page.href}
            path={page.href}
            component={page.component}
          />
        ))}
      </Switch>
      <footer className={styles.footer}>
        &copy;Platformeco, LLC, 2024
      </footer>
    </BrowserRouter>
  );
}

export default App;
