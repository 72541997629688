import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './how-to.module.css';
import CodeBlock from '../../components/code-block';

function Code({ children }: { children: React.ReactNode }) {
  return <span className={styles['inline-code']}>{children}</span>;
}

export default function HowTo() {
  const [visiblePkgExample, setVisiblePkgExample] = useState(false);

  return (
    <>
      <div className={styles.banner}>
        <h1>How to build your first component?</h1>
      </div>
      <main className={styles['content']}>
        <h2>To build new component you need to take 3 steps:</h2>
        <ol>
          <li>
            Follow this{' '}
            <a href="https://github.com/adeo/ap--platformeco-hub/tree/next/cli">
              link
            </a>{' '}
            at README file, download and install cli
          </li>
          <li>
            Create your component from scratch or use our boilerplate repository
          </li>
          <li>
            Test your component locally and publish it on npm and platformeco
            hub
          </li>
        </ol>
        <h2>Guide</h2>
        <p>Let&apos;s take a closer look to each of the steps.</p>
        <h3>pfc</h3>
        <p>
          The <Code>pfc</Code> utility you found on the link above is your
          primary tool when working with the hub. It helps you login using SSO
          and plain login/password auth and publish information about your
          component on the hub.
        </p>
        <p>
          To install that tool just run{' '}
          <Code>npm install @platformeco/pfc</Code> or other command if you
          prefer another package manager.
        </p>
        <p>
          After that you can use it either by calling{' '}
          <Code>./node_modules/.bin/pfc</Code> file directly or by creating a
          script inside <i>package.json</i> file.
        </p>
        <p>
          This cli has following requirements to how you structure your code and
          what information <i>package.json</i> file must contain:
        </p>
        <ul className={styles.list}>
          <li>
            The project&apos;s <i>package.json</i> file must contain the
            following fields:
            <ul className={styles.list}>
              <li>
                <Code>maintainers</Code> — is an array of objects with
                maintainers info;
              </li>
              <li>
                <Code>repository</Code> — is an object with required{' '}
                <Code>url</Code> field that points to the repo of your package.
              </li>
            </ul>
            Also field with name <Code>platformeco</Code> and type <i>object</i>{' '}
            is required with the following fields inside:
            <ul className={styles.list}>
              <li>
                <Code>type</Code> — is a component type: one of <i>connector</i>
                , <i>policy</i>, <i>flow</i> or <i>loader</i>;
              </li>
              <li>
                <Code>title</Code> — is a small description of your component
                within 214 characters;
              </li>
              <li>
                <Code>requirements</Code> — is an object, which should contain
                application names and tools with versions that must be installed
                on running environment;
              </li>
            </ul>
          </li>
          <li>
            <Code>platformeco</Code> object may include the following fields:
            <ul className={styles.list}>
              <li>
                <Code>installationInstruction</Code> — is an array of strings
                which will be used as an installation instruction in
                component&apos;s page sidebar
              </li>
              <li>
                <Code>upgradeInstruction</Code> — is an array of strings which
                will be used as an upgrade instruction in component&apos;s page
                sidebar
              </li>
            </ul>
          </li>
          <li>
            The project <i>package.json</i> may include the following fields:
            <ul className={styles.list}>
              <li>
                <Code>keywords</Code> — is an array of strings which will be
                shown on components page and could be used in filtering;
              </li>
              <li>
                <Code>description</Code> — is a string (length 256) which will
                be shown on components page and could be used in search.
              </li>
            </ul>
          </li>
          <li>
            The project should contain <i>README.md</i> file with an extensive
            description of your package;
          </li>
          <li>
            If nested components are present, the one or more following fields
            in <Code>platformeco</Code> object must exist:
            <ul className={styles.list}>
              <li>
                <Code>policies</Code> — is an array of strings which are paths
                to nested policies roots;
              </li>
              <li>
                <Code>connectors</Code> — is an array of strings which are paths
                to nested connectors roots;
              </li>
              <li>
                <Code>flows</Code> — is an array of strings which are paths to
                nested flows roots.
              </li>
              <li>
                <Code>loaders</Code> — is an array of strings which are paths to
                nested loaders roots.
              </li>
            </ul>
          </li>
          <li>
            Each nested component must contain a definition yaml file with a
            name of a component type, for example <Code>policy.yaml</Code>. In
            that file the following fields are required:
            <ul className={styles.list}>
              <li>
                <Code>title</Code> — is a small description of your component
                within 214 characters;
              </li>
              <li>
                <Code>description</Code> — is a string (length 256) which will
                be shown on components page and could be used in search.
              </li>
            </ul>
          </li>
          <li>
            The nested component root should contain README.md file with an
            extensive description of that component.
          </li>
        </ul>
        The requirements above may look huge, but only in writing. Here is an
        example of a valid <i>package.json</i> file:
        <br />
        <button
          className={styles['pseudo-link']}
          onClick={() => setVisiblePkgExample(!visiblePkgExample)}
        >
          Show example
        </button>
        {visiblePkgExample && (
          <CodeBlock
            value={`{
  "name": "@platformeco/amqplib-connector",
  "version": "1.8.9",
  "description": "RabbitMQ duplex connector for Platformeco using amqplib",
  "main": "dist/index.js",
  "repository": {
    "type": "git",
    "url": "https://github.com/adeo/ap--platformeco-amqplib-connector.git"
  },
  "scripts": {
    "build": "tsc",
    "pfc": "pfc"
  },
  "keywords": [
    "platformeco",
    "api",
    "amqp",
    "connector",
    "amqplib",
    "amqp 0-9-1"
  ],
  "author": {
    "name": "LMRU API Platform",
    "email": "api-platform@leroymerlin.ru",
    "url": "https://lm-tech.ru/"
  },
  "maintainers": [
    {
      "email": "platformeco-maintainers@leroymerlin.ru",
      "name": "People"
    }
  ],
  "license": "UNLICENSED",
  "dependencies": {
    "amqp-connection-manager": "4.0.0",
    "amqplib": "0.6.0",
    "hyperid": "2.0.5",
    "lodash": "4.17.19",
    "opentracing": "0.14.4",
    "pino": "6.4.1"
  },
  "platformeco": {
    "type": "connector",
    "title": "RabbitMQ duplex connector",
    "requirements": {
      "Node.js": "v12.18.3"
    },
    "policies": [
      "./src/policy/amqp-send",
      "./src/policy/nack-and-terminate"
    ]
  }
}
`}
            language="json"
          />
        )}
        <h3>Create your component</h3>
        <p>
          You can setup you component any way you like keeping in mind the rules
          above, or you can use our{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://github.com/adeo/ap--component-boilerplate"
          >
            boilerplate repo
          </a>{' '}
          for a start. It contains all necessary fields in <i>package.json</i>,
          you just need to change the values to yours.
        </p>
        <p>
          Also you can look at platformeco team components to catch an idea of a
          structure.
        </p>
        <h3>Publishing</h3>
        <p>
          First of all, if you are going to publish your component to a private
          (or corporate) registry you need to create <i>.npmrc</i> file with an
          address of that registry. For example:
          <CodeBlock
            value="$ echo 'registry=npmregistry.internal.domain' | tee .npmrc > /dev/null"
            language="bash"
          />
          You can find more information about publishing{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://docs.npmjs.com/packages-and-modules/contributing-packages-to-the-registry"
          >
            here
          </a>
          .
        </p>
        <p>
          After publishing your component to the registry, you can publish it to
          the hub.
        </p>
        <p>
          First, you need to login using <Code>pfc login</Code>. You must be in
          the project root when you execute this command. It will create a file
          called <Code>.pfcrc</Code> with an access token. <i>pfc</i> uses it to
          publish package under your name.
        </p>
        <p>
          <strong>TIP:</strong> you can use that access token in a CI if you
          want to publish component automatically. Just keep it safe and secure.
          For example, if you add to <i>YOUR_TOKEN_ENV</i> variable in the CI
          the following content with your <i>access_token</i>:
          <CodeBlock value='{ "access_token": "qewrty123" }' language="json" />
          then you can export it like this:
          <CodeBlock
            value="$ echo $YOUR_TOKEN_ENV | tee .pfcrc > /dev/null"
            language="bash"
          />
          You need to be in your project root directory.
        </p>
        <p>
          <strong>NOTE:</strong> if you installed pfc locally, you need to run
          it through npm scripts or by calling binary from{' '}
          <Code>./node_modules/.bin</Code> directory.
        </p>
        <p>
          After login you just need to execute <Code>pfc publish</Code> in the
          project root directory. Green lines after execution mean successful
          publishing, and red lines mean something is wrong. Read carefully the
          output.
        </p>
        <p>
          If everything goes well, you will see your components on the hub and
          at your <Link to="/profile/my-components">profile page</Link>.
        </p>
      </main>
    </>
  );
}
