import React from 'react';
import cn from 'classnames';

import styles from './list.module.css';

interface Props {
  items: string[] | React.ReactNode[];
  className?: string;
}

export default function List({ items, className }: Props) {
  return (
    <ul className={cn(styles.list, className)}>
      {(items as any[]).map((d, i) => (
        <li key={i}>{d}</li>
      ))}
    </ul>
  );
}
