import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import axios from 'axios';
import cn from 'classnames';

import find from './find.svg';
import styles from './search.module.css';

enum ComponentType {
  connector = 'connector',
  policy = 'policy',
  flow = 'flow',
  loader = 'loader',
}

const plural: Record<ComponentType, string> = {
  connector: 'connectors',
  policy: 'policies',
  flow: 'flows',
  loader: 'loaders',
};

interface SuggestionItem {
  name: string;
  componentType: string;
  title: string;
  description?: string;
}

interface Props {
  borderless?: boolean;
  className?: string;
}

export default function Search({ borderless, className }: Props) {
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState<SuggestionItem[]>([]);
  const [suggestionHeight, setSuggestionHeight] = useState(0);
  const ref = useRef(null);
  const refInput = useRef(null);
  const refInputHeight = useRef<number | null>(null);

  // TODO: debounce
  function fetchSearch(query: string) {
    axios
      .get(`/api/v1/search?query=${query}`)
      .then((res) => {
        setSuggestions(res.data?.data ?? []);
      })
      .catch(console.error);
  }

  useEffect(() => {
    if (search !== '') {
      fetchSearch(search);
      return;
    }

    setSuggestions([]);
  }, [search]);

  useEffect(() => {
    const handler = (e: Event) => {
      if (refInput.current !== e.target) {
        setSuggestions([]);
      }
    }

    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  });

  useLayoutEffect(() => {
    const bcr = ((ref.current as unknown) as HTMLDivElement).getBoundingClientRect();
    setSuggestionHeight(bcr.height);
  }, [suggestions]);

  useLayoutEffect(() => {
    const bcr = ((refInput.current as unknown) as HTMLDivElement).getBoundingClientRect();
    refInputHeight.current = bcr.height;
  }, []);

  return (
    <div
      className={cn(
        styles['search-block'],
        {
          [styles['search-block_borderless']]: borderless,
        },
        className
      )}
      style={{
        height: `calc(2.5em + ${suggestionHeight}px)`,
      }}
      role="search"
    >
      <input
        type="text"
        aria-label="components search"
        placeholder="Search for components"
        value={search}
        style={{
          height: (refInputHeight.current as unknown) as number,
        }}
        ref={refInput}
        onFocus={() => {
          if (search !== '') {
            fetchSearch(search);
          }
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(e.target.value);
        }}
      />
      <span className={styles['search-icon']}>
        <img alt="magnifying glass icon" src={find} />
      </span>
      <ul className={styles['suggestions-list']} ref={ref}>
        {suggestions.map((s) => {
          return (
            <li
              key={`${s.name}${s.title}${s.componentType}`}
              className={styles['suggestion-item']}
              onClick={(e) => {
                e.currentTarget.querySelector('a')?.click();
              }}
            >
              <a
                href={`/${plural[s.componentType as ComponentType]}/${s.name}`}
              >
                {s.title}
              </a>
              <br />
              {s.description !== '' && <p>{s.description}</p>}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
