import React, { ReactNode } from 'react';
import cn from 'classnames';

import styles from './sidebar.module.css';
import Checkbox from '../checkbox';

interface FilterType {
  type: string;
  name: string;
  value: string;
  title: string;
  id?: string;
  onChange?: (
    name: string,
    value: string,
    { checked, id }: { checked?: boolean; id?: string }
  ) => void;
}

interface Props {
  sections: Array<{
    title: string;
    filters: Array<FilterType>;
  }>;
  className?: string;
}

function ElementByType(f: FilterType): ReactNode {
  switch (f.type) {
    case 'checkbox':
      return (
        <Checkbox
          name={f.name}
          value={f.value}
          title={f.title}
          onChange={f.onChange}
        />
      );
    default:
      return null;
  }
}

export default function Sidebar(props: Props) {
  return (
    <div className={cn(styles['sidebar'], props.className)}>
      {props.sections.map((s) => {
        return (
          <>
            <p>{s.title}</p>
            <ul className={styles['filter-list']} aria-label={s.title}>
              {s.filters.map((f) => {
                return <li key={`${f.name}${f.value}`}>{ElementByType(f)}</li>;
              })}
            </ul>
          </>
        );
      })}
    </div>
  );
}
