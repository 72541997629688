import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'timeago.js';
import cn from 'classnames';

import styles from './example.module.css';
import Markdown from './markdown';
import List from '../../components/list';
import Button from '../../components/button/button';

const defaultComponent = {
  name: '',
  readme: '',
  versions: [{}],
};

type Version = {
  overview?: string;
  readme: string;
  repositoryLink: string;
  version: string;
}

type Example = {
  author: string;
  description: string;
  data: {
    versions: Version[];
  },
  name: string;
  version: string;
  repositoryLink: string;
  updatedAt: string;
  tags: string[];
  title: string;
  versions: Version[];
}

interface Props {
  match: {
    params: {
      exampleName: string;
      scope: string;
      exampleVersion?: string;
    };
  };
}

const tabs = [
  {
    name: 'overview',
    title: 'OVERVIEW',
  },
  {
    name: 'readme',
    title: 'README',
  },
];

const getExampleVersion = (versions: Version[], exampleVersion: string | undefined) => {
  return exampleVersion ?? versions.slice(-1)[0].version;
}

const findLatestVersion = (versions: Version[], exampleVersion: string) => {
  const matched = versions.filter(({ version }) => version === exampleVersion);
  return matched[matched.length - 1];
}

function switcher(
  activeTab: string,
  exampleVersion: string | undefined,
  example: Example
): React.ReactNode {
  const version = getExampleVersion(example.versions, exampleVersion);

  switch (activeTab) {
    case 'overview': {
      const latestVersion = findLatestVersion(example.versions, version);
      return (
        <Markdown
          markdown={latestVersion?.overview || ''}
          label="overview"
        />
      );
    }
    case 'readme': {
      const latestVersion = findLatestVersion(example.versions, version);
      return (
        <Markdown
          markdown={latestVersion?.readme || ''}
          label="readme"
        />
      );
    }
    default:
  }
}

function VersionsList({ example }: { example: Example }) {
  const [visible, setVisibility] = useState(false);

  return (
    <>
      <Button
        className={styles['versions-button']}
        onClick={() => setVisibility(!visible)}
      >
        {visible ? 'Hide' : 'Show'} {example.versions.length} versions
      </Button>
      {visible && (
        <List
          items={example.versions.reverse().map((v) => (
            <a
              key={`${example.name}/${v.version}`}
              href={`/examples/${example.name}/${v.version}`}
            >
              Version: {v.version}
            </a>
          ))}
        />
      )}
    </>
  );
}

export default function Example({
  match: {
    params: { exampleName, scope, exampleVersion },
  },
}: Props) {
  const [example, setExample] = useState<Example>(
    (defaultComponent as unknown) as Example
  );
  const [activeTab, setActiveTab] = useState('');

  let path = `/api/v1/examples/${exampleName}`;
  if (scope !== undefined && scope !== '') {
    path = `/api/v1/examples/@${scope}/${exampleName}`;
  }
  useEffect(() => {
    axios.get(path).then((resp) => {
      setExample(resp.data.data);

      if (exampleVersion) {
        if (
          (resp.data.data as Example)?.versions?.find(
            (v) => v.version === exampleVersion
          )?.overview
        ) {
          setActiveTab('overview');
          return;
        }
      }

      if ((resp.data.data as Example)?.versions.slice(-1)[0]?.overview) {
        setActiveTab('overview');
        return;
      }
      setActiveTab('readme');
    });
  }, [path, exampleVersion]);

  const TagContent = switcher(activeTab, exampleVersion, example);

  return (
    <div className={styles['component']}>
      <div>
        <h2 className={styles['component-name']}>{example.name}</h2>
        <p className={styles['component-version-time']}>
          {example.version} &#9775; Published {format(example.updatedAt)}
        </p>
      </div>
      <div>
        <ul
          role="tablist"
          aria-label="Component info"
          className={styles['tabs']}
        >
          {tabs
            .filter(
              (t) =>
                t.name !== 'overview' || example.versions.slice(-1)[0].overview
            )
            .map((t) => (
              <li
                key={t.title}
                role="tab"
                aria-selected={activeTab === t.name}
                className={cn(styles[t.name], {
                  [styles['tab-active']]: activeTab === t.name,
                })}
                id={`tab-${t.name}`}
              >
                <button onClick={() => setActiveTab(t.name)}>{t.title}</button>
              </li>
            ))}
        </ul>
      </div>
      <main className={styles['main']}>
        <div
          className={styles['item']}
          role="tabpanel"
          aria-labelledby={`tab-${activeTab}`}
        >
          <h4>
            You can see this example in a git repository{' '}
            <a
              href={
                example.versions.find((v) => v.version === exampleVersion)
                  ?.repositoryLink ||
                example.versions.slice(-1)[0].repositoryLink
              }
            >
              here
            </a>
            .
          </h4>
          {example.versions.length > 1 ? (
            <VersionsList example={example} />
          ) : null}
          {TagContent}
        </div>
      </main>
    </div>
  );
}
