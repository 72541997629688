import React from 'react';
import ReactMarkdown from 'react-markdown';
import CodeBlock from '../../components/code-block';

import styles from './markdown.module.css';

interface Props {
  markdown: string;
  label?: string;
}


export default function Markdown({ markdown, label }: Props) {
  return (
    <div className={styles['readme']} aria-label={label}>
      <ReactMarkdown
        source={markdown.replace(/\\n/g, '\n')}
        renderers={{
          code: CodeBlock,
        }}
        skipHtml={true}
      />
    </div>
  );
}
