import React, { useState } from 'react';

import styles from './checkbox.module.css';

interface Props {
  name: string;
  title: string;
  value: string;
  id?: string;
  onChange?: (
    name: string,
    value: string,
    { checked }: { checked?: boolean }
  ) => void;
}

export default function Checkbox({ name, title, value, id, onChange }: Props) {
  const [checked, setChecked] = useState(false);

  function onChangeHandler() {
    setChecked(!checked);
    if (onChange) {
      onChange(name, value, { checked: !checked });
    }
  }

  return (
    <>
      <div className={styles.checkbox}>
        <input
          type="checkbox"
          checked={checked}
          id={id ?? name}
          name={name}
          value={value}
          onChange={onChangeHandler}
        />
        <span className={styles.checked} onClick={onChangeHandler} />
        <label htmlFor={id ?? name} className={styles.label}>
          {title}
        </label>
      </div>
    </>
  );
}
