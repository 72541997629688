import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import Button from '../button/button';

import styles from './profile-icon.module.css';

export default function ProfileIcon({
  letter,
  onLogout,
}: {
  letter: string;
  onLogout?: () => void;
}) {
  const [menuVisibility, setMenuVisibility] = useState(false);
  const menuRef = useRef(null);

  // Hide menu when click outside of it
  useEffect(() => {
    function hide(e: Event) {
      if (
        menuRef.current &&
        !((menuRef.current as unknown) as HTMLUListElement).contains(
          (e.target as unknown) as Node
        )
      ) {
        setMenuVisibility(false);
      }
    }

    document.body.addEventListener('click', hide);
    return () => {
      document.body.removeEventListener('click', hide);
    };
  }, []);

  return (
    <>
      <button
        className={styles['profile-icon']}
        onClick={() => setMenuVisibility(!menuVisibility)}
      >
        {letter}
      </button>
      {menuVisibility && (
        <ul className={styles['profile-menu']} ref={menuRef}>
          <li>
            <Link
              className={styles['login-button']}
              to="/profile/my-components"
              onClick={() => setMenuVisibility(false)}
            >
              Components
            </Link>
          </li>
          <li>
            <Button
              className={styles['login-button']}
              borderless
              onClick={onLogout}
            >
              Logout
            </Button>
          </li>
        </ul>
      )}
    </>
  );
}
