import React, { useEffect, useState } from 'react';
import axios from 'axios';
import cn from 'classnames';

import styles from './components.module.css';
import blocks from './interfaces.svg';
import Item from '../../components/itemv2';
import Sidebar from '../../components/sidebar';

interface Component {
  name: string;
  version: string;
  title: string;
  href: string;
  altLogo: string;
  icon: string;
  description: string;
  author: string;
  updatedAt: string;
  tags: string[];
}

interface Props {
  match: {
    params: {
      componentType: string;
    };
  };
}

type UseFilterFn = (
  name: string,
  value: string,
  { checked }: { checked?: boolean }
) => void;

function useFilter(initial: string[]): [string[], UseFilterFn] {
  const [filters, setFilters] = useState<string[]>(initial);

  return [
    filters,
    (name: string, value: string, { checked }: { checked?: boolean }) => {
      if (checked) {
        setFilters([...filters, value]);
        return;
      }

      setFilters(filters.filter((t) => t !== value));
    },
  ];
}

export default function Connectors({
  match: {
    params: { componentType },
  },
}: Props) {
  const [components, setComponents] = useState<Component[]>([]);
  useEffect(() => {
    axios.get(`/api/v1/${componentType}`).then((resp) => {
      setComponents(resp.data.data);
    });
  }, [componentType]);

  const [tags, setFilterTags] = useFilter([]);
  const [authors, setFilterAuthors] = useFilter([]);

  const renderingComponents = components
    .filter((c) => {
      if (authors.length === 0) {
        return true;
      }

      return (
        (authors.includes('platformeco') && c.author === 'platformeco') ||
        (authors.includes('community') && c.author !== 'platformeco')
      );
    })
    .filter((c) => tags.length === 0 || tags.some((t) => c.tags.includes(t)));

  return (
    <main className={styles.page}>
      <h1 className={styles['page-title']}>
        {componentType.charAt(0).toUpperCase().concat(componentType.slice(1))}{' '}
      </h1>
      <Sidebar
        sections={[
          {
            title: 'Author',
            filters: [
              {
                type: 'checkbox',
                name: 'platformeco-tag',
                value: 'platformeco',
                title: 'platformeco',
                onChange: setFilterAuthors,
              },
              {
                type: 'checkbox',
                name: 'community-tag',
                value: 'community',
                title: 'community',
                onChange: setFilterAuthors,
              },
            ],
          },
          {
            title: 'Tags',
            filters: [
              {
                type: 'checkbox',
                name: 'http-tag',
                value: 'http',
                title: 'HTTP',
                onChange: setFilterTags,
              },
              {
                type: 'checkbox',
                name: 'amqp-tag',
                value: 'amqp',
                title: 'AMQP',
                onChange: setFilterTags,
              },
              {
                type: 'checkbox',
                name: 'jdbc-tag',
                value: 'jdbc',
                title: 'JDBC',
                onChange: setFilterTags,
              },
              {
                type: 'checkbox',
                name: 'kafka-tag',
                value: 'kafka',
                title: 'Kafka',
                onChange: setFilterTags,
              },
              {
                type: 'checkbox',
                name: 'mssql-tag',
                value: 'mssql',
                title: 'MSSQL',
                onChange: setFilterTags,
              },
              {
                type: 'checkbox',
                name: 'oracle-tag',
                value: 'oracle',
                title: 'Oracle',
                onChange: setFilterTags,
              },
              {
                type: 'checkbox',
                name: 'postgresql-tag',
                value: 'postgres',
                title: 'PostgreSQL',
                onChange: setFilterTags,
              },
              {
                type: 'checkbox',
                name: 'redis-tag',
                value: 'redis',
                title: 'Redis',
                onChange: setFilterTags,
              },
            ],
          },
        ]}
        className={styles.filters}
      />
      <ul
        className={cn(styles['items-list'], {
          [styles['items-list-short']]: renderingComponents.length < 3,
        })}
        aria-label="components"
      >
        {renderingComponents.map((c) => {
          return (
            <li key={`${componentType}/${c.name}/${c.title}`}>
              <Item
                href={`/${componentType}/${c.name}`}
                altLogo={c.altLogo}
                logo={c.icon || blocks}
                title={c.title}
                description={c.description}
                owner={`${c.author}`}
                updated={c.updatedAt}
                tags={c.tags}
              />
            </li>
          );
        })}
      </ul>
      {/*<div className={styles['components']}>*/}
      {/*  */}
      {/*</div>*/}
    </main>
  );
}
