import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios, { AxiosError } from 'axios';

import logo from './logo.svg';
import ProfileIcon from './profile-icon';
import styles from './top-nav.module.css';
import Search from '../search';
import Button from '../button/button';

interface Props {
  pages: Array<{
    href: string;
    title: string;
    navigation?: boolean;
  }>;
}

function onLogin() {
  window.location.href = `${process.env.REACT_APP_AUTH_HOST}/api/v1/auth?response_type=code&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URI}`;
}

function deleteCookie(name: string) {
  // TODO: possibly wrong
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

export default function TopNav({ pages }: Props) {
  const [authorizedUser, setAuthorizedUser] = useState<{ name: string } | null>(
    null
  );

  useEffect(() => {
    // Check whether we have a token in cookies
    // If we do, we will try to fetch a profile data
    if (!document.cookie.includes('token=')) {
      return;
    }

    axios
      .get('/api/v1/me', {
        withCredentials: true,
      })
      .then((res) => {
        setAuthorizedUser({
          name: res.data.name,
        });
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          setAuthorizedUser(null);
          return;
        }

        console.error(err.message);
      });
  }, []);

  function onLogout() {
    deleteCookie('token');
    setAuthorizedUser(null);
  }

  return (
    <header className={styles['header']}>
      <div className={styles['left-side']}>
        <img className={styles['logo']} alt="platformeco logo" src={logo} />
        <nav className={styles['nav']}>
          {pages
            .filter((p) => typeof p.navigation === 'undefined' && !p.navigation)
            .map((page) => (
              <NavLink
                key={page.href}
                exact
                to={page.href}
                activeClassName={styles['block-link-active']}
                className={styles['block-link']}
              >
                {page.title}
              </NavLink>
            ))}
        </nav>
      </div>
      <div className={styles['right-side']}>
        <Search className={styles.search} />
        {authorizedUser ? (
          <>
            <ProfileIcon
              letter={authorizedUser.name.slice(0, 1)}
              onLogout={onLogout}
            />
          </>
        ) : (
          <Button className={styles['login-button']} onClick={onLogin}>
            Login
          </Button>
        )}
      </div>
    </header>
  );
}
