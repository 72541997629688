import React from 'react';
import ReactMarkdown from 'react-markdown';
import CodeBlock from '../../components/code-block';

import styles from './markdown.module.css';

interface Props {
  markdown: string;
  label?: string;
}

const getMarkdown = (value: string) => value.replace(/ts/g, 'typescript').replace(/\\n/g, '\n');

export default function Markdown({ markdown, label }: Props) {
  const source = getMarkdown(markdown);
  return (
    <div className={styles['readme']} aria-label={label}>
      <ReactMarkdown
        source={source}
        renderers={{
          code: CodeBlock,
        }}
        skipHtml={true}
      />
    </div>
  );
}
